%footer-copy {
  font-family: $font-family--secondary;
  font-weight: $font-weight--bold;
  font-size: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
}
.article-subfooter {
  &-share {
    &-ctn {
      z-index: 2;
      position: relative;
      transform: translateZ(0);
      padding: 40px 0;
      background: #fff;
      color: $color--primary;
      @media #{$tablet} {
        padding: 80px 0;
      }
      @media #{$laptop} {
        padding: 100px 0;
      }
    }
    &-text {
      margin-bottom: 25px;
      @extend %footer-copy;
      text-align: center;
    }
    & {
      z-index: 2;
      position: relative;
      transform: translateZ(0);
      display: flex;
      width: 100%;
      max-width: 330px;
      padding: 0 10px;
      margin: 0 auto;
      justify-content: space-around;
      text-align: center;
    }
    &-icon {
      width: 60px;
      height: 60px;
      background-color: #000;
      border: 1px solid #000;
      transition: 0.2s;
      svg {
        width: 60px;
        height: 60px;
        padding: 20px;
        fill: #fff;
      }
      &:hover{
        border: 1px solid #000;
        background-color: #fff;
        svg{
          fill: #000;
        }
      }
    }
  }
  & {
    position: relative;
    z-index: 2;
    color: $color--primary;
  }
  &-photocredits {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 50px 20px;
    @media #{$tablet} {
      padding: 60px 20px;
    }
    &-trigger {
      font-size: 20px;
      letter-spacing: 0.05em;
      text-decoration: line-through;
      transition: $base-transition;
      .no-touchevents &:hover,
      .desktop-touch &:hover {
        color: $color--secondary;
      }
    }
  }
  .issue-text {
    z-index: 2;
    position: absolute;
    bottom: auto;
    transform: translate(0,-50%);
    display: block;
    width: 100%;
  }
  &-links {
    z-index: 1;
    position: relative;
    height: 120px;
    overflow: hidden;
    @media #{$tablet} {
      height: 200px;
    }
    .no-touchevents &,
    .desktop-touch & {
      @media #{$tablet} {
        height: 300px;
      }
    }
    &:before,
    &:after {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $white;
    }
    &:before{
      background-image: url(../assets/img/bg-pattern.png);
      background-size: 15px 15px;
      opacity: 1;
    }
    &:after{
      background-color: $black;
      opacity: 0.45;
      background-size: 10% auto;
      @media #{$tablet} {
        background-size: auto;
      }
    }
    // &:after {
    //   background-image: url(../assets/img/bg-pattern.png);
    //   background-size: 15px 15px;
    //   opacity: 0.5;
    // }
    .prev-issue,
    .next-issue {
      @extend %footer-copy;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(0,-50%) translateZ(0);
      & {
        pointer-events: none;
        opacity: 0;
        &:link {
          pointer-events: all;
          opacity: 1;
        }
      }
      .no-touchevents &,
      .desktop-touch & {
        transition: 0.3s ease-out;
        &:hover {
          color: #fff;
        }
      }
    }
    .prev-issue {
      left: 20px;
      @media #{$tablet} {
        left: 40px;
      }
      .no-touchevents & ~ .article-subfooter-prev-image,
      .desktop-touch & ~ .article-subfooter-prev-image {
        @media #{$tablet} {
          transition: 0.3s ease-out;
        }
      }
      .no-touchevents &:hover ~ .article-subfooter-prev-image,
      .desktop-touch &:hover ~ .article-subfooter-prev-image {
        @media #{$tablet} {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .next-issue {
      right: 20px;
      @media #{$tablet} {
        right: 40px;
      }
      .no-touchevents & ~ .article-subfooter-next-image,
      .desktop-touch & ~ .article-subfooter-next-image {
        @media #{$tablet} {
          transition: 0.3s ease-out;
        }
      }
      .no-touchevents &:hover ~ .article-subfooter-next-image,
      .desktop-touch &:hover ~ .article-subfooter-next-image {
        @media #{$tablet} {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  &-prev-image,
  &-next-image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: calc(100% + 20px);
    height: 100%;
    visibility: hidden;
    opacity: 0;
  }
  &-link-copy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 500px;
    text-align: center;
    font-family: $font-family--primary;
    color: #fff;
    @media #{$laptop} {
      max-width: 650px;
    }
  }
  &-link-heading {
    font-size: 43px;
    line-height: 1.27em;
    margin-bottom: 10px;
  }
  &-link-author {
    font-size: 30px;
    font-style: italic;
  }
}
