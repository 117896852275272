.crop {
  &-mark,
  &-line {
    fill: none;
    stroke-width: 1px;
    stroke: #000;
    position: absolute;
    z-index: 1;
  }
}
.frame {
  &-top,
  &-bottom,
  &-left,
  &-right {
    display: none;
    visibility: hidden;
    .home-page &,
    .about-page &,
    .contact-page & {
      @media #{$tablet} {
        display: block;
        visibility: visible;
        position: fixed;
        z-index: 1000;
      }
    }
  }
}
.frame {
  &-top {
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    & .crop {
      &-mark {
        top: 10px;
        &.top-left {
          left: 20px;
        }
        &.top-right {
          right: 20px;
        }
      }
      &-line {
        transform: rotate(90deg);
        &.top-left {
          left: 140px;
        }
        &.top-right {
          right: 140px;
        }
      }
    }
  }
  &-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    & .crop-line {
      bottom: 0;
      transform: rotate(90deg);
      &.bottom-left {
        left: 140px;
      }
      &.bottom-right {
        right: 140px;
      }
    }
  }
  &-left {
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    & .crop-line {
      &.left-top {
        top: 140px;
      }
      &.left-bottom {
        bottom: 140px;
      }
    }
  }
  &-right {
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    & .crop-line {
      right: 0;
      &.right-top {
        top: 140px;
      }
      &.right-bottom {
        bottom: 140px;
      }
    }
  }

}

.nav-trigger-ctn,
.nav-close-ctn,
.share-trigger-ctn,
.share-close-ctn,
.email-trigger-ctn,
.email-close-ctn,
.photocredits-close-ctn {
  display: none;
  @media #{$tablet} {
    position: fixed;
    z-index: 1005;
    top: 50%;
    display: block;
    width: 30px;
    cursor: pointer;
    button {
      position: absolute;
      padding: 5px;
      font-family: $font-family--secondary;
      font-weight: $font-weight--bold;
      font-size: 13px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      transition: $base-transition;
      &:hover {
        letter-spacing: 1em;
      }
    }
  }
}

.email-close-x,
.share-close-x,
.photocredits-close-x {
  position: fixed;
  z-index: 1006;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  transition: $animation-transition;
  visibility: hidden;
  opacity: 0;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    width: 25px;
    height: 2px;
    background: $color--primary;
    transition: $base-transition;
    opacity: 1;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.nav-trigger-ctn,
.share-trigger-ctn,
.email-trigger-ctn {
  transition: $animation-transition;
  opacity: 1;
  visibility: visible;
}

.nav-close-ctn,
.share-close-ctn,
.email-close-ctn,
.photocredits-close-ctn {
  transition: opacity $animation-transition, visibility $animation-transition;
  opacity: 0;
  visibility: hidden;
  .share-open & {
    opacity: 1;
    visibility: visible;
  }
}

.nav-close,
.share-close,
.email-close,
.photocredits-close {
  position: absolute;
  right: 50%;
  transform: rotate(0) translateX(50%) translateZ(0);
}

.nav-trigger-ctn {
  left: 0;
  transform: rotate(-90deg);
  .share-open &,
  .email-open &,
  .photocredits-open & {
    opacity: 0;
    visibility: hidden;
  }
}
.nav-trigger {
  position: absolute;
  left: 50%;
  transform: rotate(0) translateX(-50%) translateZ(0);
  color: $color--secondary;
  .error-page & {
    color: #fff;
  }
  .nav-open & {
    letter-spacing: 1em;
    color: $color--primary;
  }
}
.nav-close-ctn {
  right: 0;
  transform: rotate(90deg);
  .nav-open & {
    color: $color--primary;
    opacity: 1;
    visibility: visible;
  }
}
.share-trigger-ctn {
  right: 0;
  transform: rotate(90deg);
  .nav-open &,
  .email-open &,
  .photocredits-open & {
    opacity: 0;
    visibility: hidden;
  }
}
.share-trigger {
  position: absolute;
  right: 50%;
  transform: rotate(0) translateX(50%) translateZ(0);
  color: $color--secondary;
  .error-page & {
    color: #fff;
  }
  .share-open & {
    letter-spacing: 1em;
    color: $color--primary;
  }
}
.share-close-ctn {
  left: 0;
  transform: rotate(-90deg);
  .share-open & {
    color: $color--primary;
    opacity: 1;
    visibility: visible;
  }
}
.share-close-x {
  .share-open & {
    opacity: 1;
    visibility: visible;
    @media #{$tablet} {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.email-close-ctn {
  &.left {
    left: 0;
    transform: rotate(-90deg);
  }
  &.right {
    right: 0;
    transform: rotate(90deg);
  }
  .email-open & {
    color: $color--primary;
    opacity: 1;
    visibility: visible;
  }
  .nav-open &,
  .share-open &,
  .photocredits-open & {
    opacity: 0;
    visibility: hidden;
  }
}
.email-close-x {
  .email-open & {
    opacity: 1;
    visibility: visible;
    @media #{$tablet} {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.photocredits-close-ctn {
  &.left {
    left: 0;
    transform: rotate(-90deg);
  }
  &.right {
    right: 0;
    transform: rotate(90deg);
  }
  .photocredits-open & {
    opacity: 1;
    visibility: visible;
  }
}
.photocredits-close-x {
  .photocredits-open & {
    opacity: 1;
    visibility: visible;
    @media #{$tablet} {
      visibility: hidden;
      opacity: 0;
    }
  }
}
