[data-share-menu] {
  z-index: 1004;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 0 0;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  transition: $animation-transition;
  background-image: url(../assets/img/bg-pattern.png);
  background-size: 15px 15px;
  color: $color--primary;
  .share-open & {
    visibility: visible;
    opacity: 1;
  }
  .share-trigger-ctn{
    .home-page &,
    .about-page &,
    .contact-page & {
      display: none;
    }
  }
}
.share {
  &-ctn {
    position: relative;
    display: block;
    height: 100%;
    max-height: 100%;
    padding: 80px 20px 60px;
    @media #{$tablet} {
      padding: 120px 60px;
    }
    @media #{$desktop} {
      padding: 120px 85px 85px;
    }
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  &-close-bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  & {
    @extend %clearfix;
    z-index: 2;
    position: relative;
    display: inline-block;
    transform: scale(0.95);
    text-align: center;
    transition: $animation-transition;
    .share-open & {
      transform: scale(1);
    }
  }
  &-heading {
    font-family: $font-family--primary;
    font-weight: $font-weight--normal;
    font-size: 40px;
    letter-spacing: 0.025em;
    line-height: 1em;
    padding-bottom: 35px;
    @media #{$laptop} {
      font-size: 45px;
    }
  }
  &-image {
    max-width: 600px;
    margin: 0 auto;
  }
  &-title {
    font-family: $font-family--primary;
    font-weight: $font-weight--normal;
    font-size: 25px;
    letter-spacing: 0.025em;
    line-height: 1em;
    padding: 45px 0 25px;
    @media #{$tablet} {
      font-size: 40px;
    }
    @media #{$laptop} {
      font-size: 50px;
    }
  }
  &-author {
    font-family: $font-family--secondary;
    font-weight: $font-weight--bold;
    font-size: 11px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    padding-bottom: 45px;
    @media #{$tablet} {
      font-size: 14px;
    }
  }
  &-details {
    font-family: $font-family--primary;
    font-weight: $font-weight--normal;
    font-style: italic;
    font-size: 22px;
    letter-spacing: 0.025em;
    line-height: 1.4em;
    @media #{$tablet} {

    }
    span {
      display: block;
      @media #{$tablet} {
        display: inline-block;
      }
    }
  }
  &-handle {}
  &-tags {}
  &-url {
    font-family: $font-family--primary;
    font-weight: $font-weight--normal;
    font-style: italic;
    font-size: 19px;
    letter-spacing: 0.025em;
    line-height: 1.4em;
    color: $color--secondary;
    padding-bottom: 25px;
    @media #{$tablet} {
      font-size: 22px;
    }
    a {
      transition: $base-transition;
      opacity: 1;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  &-links-ctn {}
  &-link {
    margin-bottm: 40px;
    @media #{$tablet} {
      display: inline-block;
      margin-right: 40px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    a {
      position: relative;
      display: inline-block;
      font-family: $font-family--secondary;
      font-weight: $font-weight--normal;
      font-size: 14px;
      text-transform: uppercase;
      line-height: 1em;
      transition: $base-transition;
      opacity: 1;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $color--primary;
        transform: translateZ(0);
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
