[data-photocredits-menu] {
  z-index: 1004;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  transition: $animation-transition;
  background-image: url(../assets/img/bg-pattern.png);
  background-size: 15px 15px;
  color: $color--primary;
  .photocredits-open & {
    opacity: 1;
    visibility: visible;
  }
}
.photocredits {
  &-ctn {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    transform: scale(0.95);
    transition: $animation-transition;
    -webkit-overflow-scrolling: touch;
    padding: 80px 20px 60px;
    @media #{$tablet} {
      padding: 120px 60px;
    }
    @media #{$desktop} {
      padding: 120px 85px 85px;
    }
    .photocredits-open & {
      transform: scale(1);
    }
  }
  & {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
  }
  &-heading {
    position: relative;
    z-index: 1;
    font-size: 40px;
    margin: 0 0 30px;
    text-align: center;
    @media #{$tablet} {
      font-size: 54px;
      margin: 0 0 40px;
    }
    @media #{$laptop} {
      font-size: 67px;
      margin: 0 0 60px;
    }
    @media #{$desktop} {
      margin: 0 0 100px;
    }
  }
  &-grid {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
    @media #{$laptop} {
      max-width: 1400px;
    }
  }
  &-item {
    & {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 0;
      @media #{$laptop} {
        padding: 10px 20px;
      }
      @media #{$desktop} {
        width: 50%;
      }
      @media #{$mac} {
        padding: 10px 40px;
      }
    }
    &-image {
      &-ctn {
        display: none;
        @media #{$tablet} {
          position: relative;
          display: block;
          width: 40%;
          height: auto;
        }
        &:before {
          @media #{$tablet} {
            content: "";
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
          }
        }
      }
      & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    &-content {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      @media #{$tablet} {
        width: 60%;
        max-width: none;
        padding: 0 0 0 40px;
      }
    }
    &-credits {
    }
    &-url {
      word-break: break-all;
    }
  }
}
