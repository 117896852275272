// ==========================================================================
// TYPOGRAPHY
// - font-family
// - font-weight
// - font-size
// - line-height
// - letter-spacing
// ==========================================================================

%copy-text {
  font-family: $font-family--primary;
  font-weight: $font-weight--normal;
  font-size: $base__font-size;
  line-height: 1.5em;
  letter-spacing: 0em;
}

%title-text {
  font-family: $font-family--primary;
  font-weight: $font-weight--normal;
  font-size: 70px;
  line-height: 1.14em;
  letter-spacing: 0em;
}

%site-link-text {
  font-family: $font-family--secondary;
  font-weight: $font-weight--bold;
  font-size: 20px;
  letter-spacing: 0.025em;
  line-height: 1.5em;
  @media #{$laptop} {
    font-size: 27px;
  }
}
%article-link-text {
  font-family: $font-family--primary;
  font-weight: $font-weight--normal;
  font-size: 25px;
  letter-spacing: 0.025em;
  line-height: 1.5em;
  @media #{$laptop} {
    font-size: 34px;
  }
}
