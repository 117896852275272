.footer {
  z-index: 2;
  position: relative;
  transform: translateZ(0);
  display: block;
  text-align: center;
  color: $white;
  padding: 50px 25px;
  background-color: #010101;
  background-image: url(../assets/img/footer-bg.jpg);
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  .logo {
    position:relative;
    left: 0;
    transform: none;
    svg {
      fill: $white;
    }
  }
  p {
    padding-top: 25px;
    font-size: 14px;
    letter-spacing: 0.01em;
  }
}
