.header {
  z-index: 1005;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  transform: translateY(0);
  transition: $animation-transition;
  @media #{$tablet} {
    height: 60px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 1;
    transition: 0.45s;
    transform: translateY(-100%);
    @media #{$tablet} {
      opacity: 0;
      transform: translateY(0);
      transition: 0s;
    }
  }
  html:not(.nav-open):not(.share-open):not(.email-open):not(.photocredits-open).scrolled .article &:before,
  html:not(.nav-open):not(.share-open):not(.email-open):not(.photocredits-open).scrolled .article &:before {
    transform: translateY(0);
    opacity: 1;
    transition: 0.3s;
  }
  .nav-open .article &:before,
  .share-open .article &:before,
  .email-open .article &:before,
  .photocredits-open .article &:before {
    opacity: 0;
    transform: translateY(0);
    transition: 0.45s;
  }
  .logo {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 10px;
    transform: translate(0,0);
    svg {
      fill: $color--primary;
      transition: $animation-transition;
    }
    @media #{$tablet} {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .outro-page &,
    .error-page & {
      svg {
        fill: #fff;
      }
    }
    .home-page & {
      svg {
        fill: $color--primary;
      }
    }
    .scrolled .outro-page &,
    .scrolled .error-page & {
      svg {
        fill: #fff;
      }
    }
    :not(.nav-open):not(.share-open):not(.email-open):not(.photocredits-open).scrolled .home-page & {
      svg {
        fill: $color--secondary;
      }
    }
    .nav-open &,
    .share-open &,
    .email-open &,
    .photocredits-open & {
      left: 50%;
      transform: translate(-50%,0);
      @media #{$tablet} {
        transform: translate(-50%,-50%);
      }
      svg {
        fill: $color--primary;
      }
    }
    .scrolled .article & {
      left: 10px;
      transform: translate(0,0);
      @media #{$tablet} {
        transform: translate(0,-50%);
      }
      @media #{$laptop} {
        left: 60px;
      }
    }
    .nav-open.scrolled .article &,
    .share-open.scrolled .article &,
    .email-open.scrolled .article &,
    .photocredits-open.scrolled .article & {
      left: 50%;
      transform: translate(-50%,0);
      @media #{$tablet} {
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .nav-open.scrolled .outro-page &,
    .share-open.scrolled .outro-page &,
    .email-open.scrolled .outro-page &,
    .photocredits-open.scrolled .outro-page &,
    .nav-open.scrolled .error-page &,
    .share-open.scrolled .error-page &,
    .email-open.scrolled .error-page &,
    .photocredits-open.scrolled .error-page & {
      svg{
        fill: #000;
      }
    }
  }
  .article-title {
    display: none;
    @media #{$laptop} {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 245px;
      transform: translate(0,-50%);
      display: block;
      margin-top: 4px;
      font-size: 24px;
      opacity: 0;
      transition: $base-transition;
      transition-delay: 0s;
    }
    .scrolled .article & {
      opacity: 1;
      transition-delay: 0.45s;
    }
    .nav-open.scrolled .article &,
    .share-open.scrolled .article &,
    .email-open.scrolled .article &,
    .photocredits-open.scrolled .article & {
      opacity: 0;
      visibility: hidden;
      transition: 0.25s;
      transition-delay: 0s;
    }
  }
}
.hamburger {
    position: fixed;
    z-index: 1006;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    transition: $animation-transition;
    @media #{$tablet} {
      visibility: hidden;
      opacity: 0;
      right: 17px;
    }
    & span {
      position: absolute;
      width: 25px;
      height: 2px;
      background: $color--primary;
      transition: $base-transition;
      .outro-page &,
      .error-page & {
        background: #fff;
      }
      &:nth-of-type(1) {
        top: 50%;
        left: 5px;
        margin-top: -10px;
        .nav-open &,
        .share-open &,
        .email-open & {
          transform: translateX(50px);
        }
      }
      &:nth-of-type(2) {
        top: 50%;
        left: 5px;
        .nav-open &,
        .share-open &,
        .email-open &,
        .photocredits-open & {
          transform: translateX(-50px);
        }
      }
      &:nth-of-type(3) {
        top: 50%;
        left: 5px;
        margin-top: 10px;
        .nav-open &,
        .share-open &,
        .email-open &,
        .photocredits-open & {
          transform: translateX(50px);
        }
      }
    }
    .nav-open & span,
    .share-open & span,
    .email-open & span,
    .photocredits-open & span {
      background: $color--primary;
      &:nth-of-type(1) {
        transform: translateX(50px);
      }
      &:nth-of-type(2) {
        transform: translateX(-50px);
      }
      &:nth-of-type(3) {
        transform: translateX(50px);
      }
    }
    .no-touchevents:not(.nav-open):not(.share-open):not(.email-open):not(.photocredits-open) &:hover,
    .desktop-touch:not(.nav-open):not(.share-open):not(.email-open):not(.photocredits-open) &:hover {
      & span:nth-of-type(1) {
        transform: translateX(3px);
      }
      & span:nth-of-type(2) {
        transform: translateX(-3px);
      }
      & span:nth-of-type(3) {
        transform: translateX(3px);
      }
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 5px;
      width: 25px;
      height: 2px;
      background: $color--primary;
      transition: $base-transition;
      opacity: 0;
      .nav-open &,
      .share-open &,
      .email-open &,
      .photocredits-open & {
        opacity: 1;
      }
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
.progress-bar {
  position: absolute;
  top: 100%;
  left: 0;
  width: 0%;
  height: 4px;
  background: $black;
  transform: translateX(0) translateY(-50%);
  transition: transform $animation-transition;
  transition-delay: 0;
  html:not(.scrolled) & {
    width: 0% !important;
  }
  .nav-open &,
  .share-open &,
  .email-open &,
  .photocredits-open & {
    transform: translateX(-100%) translateY(-50%);
    transition: transform $animation-transition;
    transition-delay: 0s;
  }
  .home-page &,
  .about-page &,
  .contact-page &,
  .outro-page &,
  .error-page & {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
