[data-email-menu] {
  z-index: 1004;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  transition: $animation-transition;
  background-image: url(../assets/img/bg-pattern.png);
  background-size: 15px 15px;
  color: $color--primary;
  @media #{$tablet} {
    padding: 120px 60px;
  }
  @media #{$desktop} {
    padding: 120px 85px 85px;
  }
  .email-open & {
    visibility: visible;
    opacity: 1;
  }
}
.email {
  &-ctn {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    transform: translate(-50%,-50%) scale(0.95);
    transition: $animation-transition;
    @media #{$desktop} {
      max-width: 880px;
    }
    .email-open & {
      transform: translate(-50%,-50%) scale(1);
    }
  }
  &-close-bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  &-form {
    position: relative;
    display: block;
    padding: 20px;
    .subscription-message {
      &-ctn {
        position: absolute;
        top: 100%;
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }
      &-text {
        font-family: $font-family--secondary;
        font-size: 16px;
        color: #000;
      }
    }
  }
  &-field {
    position: relative;
    width: 100%;
    @media #{$desktop} {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-100%);
      width: 100%;
      margin-top: -0.5em;
    }
  }
  &-input {
    position: relative;
    z-index: 1;
    width: 100%;
    border-bottom: 3px solid #000;
    input[type="text"] {
      width: 100%;
      font-size: 32px;
      line-height: 1em;
      height: 1.2em;
      min-height: 1.2em;
      margin-top: 50px;
      padding-right: 75px;
      @media #{$desktop} {
        margin-top: 0;
        padding-right: 120px;
        font-size: 70px;
      }
    }
  }
  &-submit {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    input[type="submit"] {
      font-family: $font-family--secondary;
      font-weight: $font-weight--bold;
      font-size: 14px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      cursor: pointer;
      transition: $base-transition;
      &:hover {
        @media #{$desktop} {
          letter-spacing: 0.7em;
        }
      }
    }
  }
  &-copy {
    color: #bbbbbb;
    @media #{$desktop} {
      color: #d0d0d0;
    }
    span {
      display: block;
      font-size: 40px;
      line-height: 1.5em;
      @media #{$desktop} {
        font-size: 70px;
      }
    }
    @media #{$desktop} {
      position: relative;
      z-index: 1;
    }
  }
  &-disclaimer {
    display: none;
    @media #{$desktop} {
      display: block;
      margin-top: 30px;
      font-family: $font-family--secondary;
      font-size: 11px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
  }
}
