// ==========================================================================
// EXTENDS
// ==========================================================================

// Clearfix
  %clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

// Font Smoothing
  %font-smoothing {
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  }
// Content Background Image
  %content-bg-image {
    display: none;
    position: absolute;
    z-index: -1;
    width: 100%;
    max-width: 370px;
    height: auto;
    transform: translate(-50%,-50%);
    // filter: grayscale(1);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media #{$tablet} {
      position: fixed;
      z-index: -1;
      top: 50%;
      left: 50%;
      display: block;
      width: 70%;
      max-width: 650px;
      height: auto;
      visibility: hidden;
      opacity: 0;
      transition: $base-transition;
    }
    &.bg-active {
      visibility: visible;
      opacity: 1;
    }
    &:after {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 80%;
      background: #fff;
      opacity: 0.8;
      @media #{$tablet}{
        background: transparent;
        opacity: 0;
      }
    }
  }
