.hero {
  position: relative;
  width: 100%;
  height: 100%;
  body:not(.article):not(.error-page):not('.outro-page') &-content {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    @media #{$tablet} {
      border: 60px solid #fff;
    }
  }
}
.article-hero {
  &-content {
    position: relative;
    z-index: 3;
    @media #{$max-mobile} {
      height: auto !important;
    }
    @media #{$tablet} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .article & .issue-text {
      & {
        @media #{$tablet} {
          bottom: 0;
        }
        .prev-issue,
        .next-issue {
          position: absolute;
          top: 0;
          transition: $base-transition;
          &:hover {
            color: $color--secondary;
          }
        }
        .prev-issue {
          left: 60px;
        }
        .next-issue {
          right: 60px;
        }
      }
    }
  }
  &-share {
    &-ctn {
      position: absolute;
      transform: translateY(-50%);
      width: 100%;
      overflow: hidden;
    }
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    max-width: 330px;
    padding: 0 10px;
    margin: 0 auto;
    justify-content: space-around;

    @media #{$tablet} {
      display: none;
    }
    &-icon {
      width: 60px;
      height: 60px;
      background-color: #fff;
      border: 1px solid #000;
      svg {
        width: 60px;
        height: 60px;
        padding: 20px;
        fill: #000;
      }
    }
  }
  &-share.dark &-share-icon {
    background-color: #000;
    border: 1px solid #fff;
    svg {
      fill: #fff;
    }
  }
  &-copy {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 90px 20px 60px;
    text-align: center;
    @media #{$tablet} {
      position: absolute;
      padding: 60px;
    }
  }
}
