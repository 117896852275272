[data-nav-menu] {
  z-index: 1004;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 0 0;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  transition: $animation-transition;
  background-image: url(../assets/img/bg-pattern.png);
  background-size: 15px 15px;
  color: $color--primary;
  .nav-open & {
    visibility: visible;
    opacity: 1;
  }
  .nav-trigger-ctn{
    .home-page &,
    .about-page &,
    .contact-page & {
      display: none;
    }
  }
}
.nav {
  &-ctn {
    position: relative;
    display: block;
    height: 100%;
    max-height: 100%;
    padding: 80px 20px 0;
    @media #{$tablet} {
      padding: 120px 60px 0;
    }
    @media #{$desktop} {
      padding: 120px 85px 0;
    }
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  &-close-bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  & {
    @extend %clearfix;
    z-index: 2;
    position: relative;
    display: inline-block;
    transform: scale(0.95);
    text-align: center;
    transition: $animation-transition;
    padding: 0 0 60px;
    @media #{$tablet} {
      padding: 0 0 120px;
    }
    @media #{$desktop} {
      padding: 0 0 85px;
    }
    .nav-open & {
      transform: scale(1);
    }
  }
  &-title {
    @extend %site-link-text;
    padding-bottom: 20px;
    color: $color--primary;
  }
  &-item {
    position: relative;
    display: block;
    margin: 20px auto 0;
    // font-size: 16px;
    // letter-spacing: 0.025em;
    // line-height: 1.2em;
    color: $color--primary;
    @media #{$laptop} {
      max-width: 1000px;
      font-size: 20px;
    }
  }
}

[data-link-type="article"] {
  counter-increment: navLinks;
}
.article-link {
  @extend %article-link-text;
  position: relative;
  display: inline-block;
  padding: 0;
  transition: $base-transition;
  &:before { // nav link number
    content: counter(navLinks);
    position: absolute;
    transform: translate(-100%, -21%);
    display: inline-block;
    padding-right: 2px;
    font-size: 15px;
    font-family: $font-family--secondary;
    font-weight: $font-weight--bold;
    color: $color--secondary;
    text-decoration: none !important;
    @media #{$laptop} {
      padding-bottom: 0;
      font-weight: 400;
    }
  }
  &:hover {
    opacity: 0.4;
  }
}
.site-link {
  @extend %site-link-text;
  transition: $base-transition;
  &:hover {
    opacity: 0.4;
  }
}
