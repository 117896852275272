// ==========================================================================
// BASE
// - global styles
// ==========================================================================

html,
body {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  color: $color--primary;
  @extend %copy-text;
}
html.nav-open,
html.share-open,
html.email-open,
html.photocredits-open {
  overflow: hidden;
}
body {
  position: relative;
}
h1,
h2,
h3,
h4,
h5 {
  @extend %title-text;
}
h1,
h2,
h3,
h4,
h5,
p,
nav,
header,
footer,
.title {
  display: block;
  margin: 0 auto;
  padding: 0;
  line-height: 1em;
}

header ul,
footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: $white;
}

header ul a,
footer ul a {
  color: $white;
  text-decoration: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

button {
  border: 0;
  outline: 0;
  background: inherit;
  cursor: pointer;
}

input {
  border: 0;
  outline: 0;
  background: inherit;
}

img {
  width: 100%;
  height: auto;
  outline: none;
  backface-visibility: hidden;
}

div.hidden,
span.hidden {
  display: none;
  visibility: hidden;
}

span.strikethrough,
span.underline {
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color--primary;
  }
}
span.strikethrough:after {
  top: 50%;
}
span.underline:after {
  bottom: 0;
}
.text-link {
  display: inline-block;
  text-decoration: line-through;
  color: $color--secondary;
  transition: $base-transition;
  &:hover {
    color: $color--primary;
  }
}
.strike-through-link {
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    position: absolute;
    top: 60%;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    background-color: $color--primary;
    transform: translateZ(0);
    transition: 0.25s ease-out;;
  }
  &:hover:after {
    width: 100%;
    transform: translateZ(0);
  }
}

main {
  z-index: 1;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
}

.logo {
  display: inline-block;
  width: 138px;
  height: 45px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 138px;
    height: 45px;
    stroke-width: 0;
    fill: $color--primary;
  }
}
.issue-text {
  & {
    display: none;
    font-family: $font-family--secondary;
    font-weight: $font-weight--bold;
    font-size: 16px;
    letter-spacing: 0.2em;
    color: $color--primary;
    text-transform: uppercase;
    text-align: center;
    @media #{$tablet} {
      z-index: 2;
      position: absolute;
      bottom: 60px;
      left: 0;
      display: block;
      width: 100%;
      transform: translateY(50%);
    }
  }
  &.light {
    color: #fff;
  }
}

[data-img-src] {}
[data-bg-src] {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
[data-before-src] {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
[data-after-src] {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
