// ==========================================================================
// CONFIGS
// - global variables
// ==========================================================================

// Text
  $base__font-size : 18px;

// Font Weights
  $font-weight--thin       : 100;
  $font-weight--light      : 300;
  $font-weight--normal     : 400;
  $font-weight--semibold   : 600;
  $font-weight--bold       : 700;
  $font-weight--ultra-bold : 900;

// Font Stacks
  $font-family--primary      : "Crimson Text", serif; // copy
  $font-family--secondary    : "Arial", sans-serif;

// Descriptive Base Colors
  $white    : #ffffff;
  $black    : #161616;
  $gray     : #b2b2b2;
  // $blue     : #010180;

  $color--primary     : $black;
  $color--secondary   : $gray;

// Transitions
  $base-transition      : 0.3s ease-out;
  $animation-transition : 0.45s ease-in-out;

// Responsive Sizes
  // USAGE EXAMPLE
  // @media #{$max-mobile} {
  //   code: here
  // }
  //

  $tablet      : "(min-width: 768px)";
  $laptop      : "(min-width: 1024px)";
  $desktop     : "(min-width: 1200px)";
  $mac         : "(min-width: 1400px)";
  $max-desktop : "(max-width: 1399px)";
  $max-laptop  : "(max-width: 1199px)";
  $max-tablet  : "(max-width: 1023px)";
  $max-mobile  : "(max-width: 767px)";

  // PostCSS Aliases
  @alias {
    // font styles
    ff: font-family;
    fw: font-weight;
    fs: font-size;

    // other
    bgc: background-color;
  }
